import axios from 'axios';
import React, { useCallback, useEffect, useState, useMemo } from 'react'
// @ts-expect-error React Select types are incompatible with our component props
import Select from 'react-select';
import dayjs from 'dayjs';
import debounce from 'lodash.debounce';
import { Button, ButtonGroup, Card, CardHeader, Col, Container, Input, Row, Table } from 'reactstrap'
import { Link } from 'react-router-dom';
import Pagination from '../../../components/Pagination';
import { Lead, PaginatedLeadResponse } from './types';
import LeadDetailModal from './LeadDetailModal';
import BulkUpdateModal from './BulkUpdateModal';


function LeadsList() {
    const [loading, setLoading] = useState(false);
    const [state, setState] = useState('open');
    const [query, setQuery] = useState('');
    const [searchType, setSearchType] = useState('employee_no');
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(1);
    const [leads, setLeads] = useState<Lead[]>([]);
    const [selectedLeads, setSelectedLeads] = useState<Lead[]>([]);

    // Function to get the display label for a status
    const getStatusLabel = (status: string): string => {
        switch (status) {
            case 'open':
                return 'New';
            case 'in_progress':
                return 'In Progress';
            case 'duplicate':
                return 'Duplicate';
            case 'drop_off':
                return 'Drop Off';
            case 'closed':
                return 'Resolved';
            case '':
                return 'All';
            default:
                return 'New';
        }
    };
    
    // Using the current value of query directly from the function parameter
    // instead of relying on the closure value
    const getLeads = useCallback((currentQuery = query) => {
        setLoading(true);
        axios.get('/api/leads/', {
            params: {
                status: state,                       // 'open', 'in_progress', or 'closed'
                page,                                // Current page number
                size: 50,                            // Items per page
                // Map search types to appropriate parameters
                ...(currentQuery && searchType === 'employee_no' && { employee_no: currentQuery }),
                ...(currentQuery && searchType === 'id_number' && { employee_id_number: currentQuery }),
                ...(currentQuery && searchType === 'cellphone' && { employee_mobile: currentQuery }),
                ...(currentQuery && searchType === 'reference' && { lead_id: currentQuery })
            }
        })
        .then((res) => res.data)
        .then((data: PaginatedLeadResponse) => {
            setLeads(data.results);              // Update to 'results' instead of 'leads'
            setPages(data.pages);                // Total number of pages
            setPage(data.page);                  // Current page
            setLoading(false);
        })
        .catch((err) => {
            console.log(err);
            setLoading(false);
        });
    }, [page, state, query, searchType]);

    // Debounced search function
    const debouncedSearch = useMemo(() => 
        debounce(() => {
            getLeads(query);
        }, 500), 
        [getLeads, query]
    );

    // Handle status change
    const handleStatusChange = useCallback((option: any) => {
        setState(option.value);
        setQuery('');
        setPage(1);
    }, []);
    
    // Handle search type change
    const handleSearchTypeChange = useCallback((type: string) => {
        setSearchType(type);
        if (query !== '') {
            debouncedSearch();
        }
    }, [query, debouncedSearch]);

    const handleQueryChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const newQuery = e.target.value;
        
        // If query is being cleared, handle this specifically
        if (newQuery === '' && query !== '') {
            // Cancel any pending debounced search
            debouncedSearch.cancel();

            // Set the query state first
            setQuery('');

            // Reload data with an explicitly empty query
            getLeads('');
        } else {
            // Otherwise just update the query normally
            setQuery(newQuery);
        }
    }, [query, debouncedSearch, getLeads]);

    useEffect(() => {
        getLeads();
    }, [page, state]);

    // Effect for query changes
    useEffect(() => {
        if (query !== '') {
            debouncedSearch();
        }
        return () => {
            debouncedSearch.cancel();
        };
    }, [query, debouncedSearch]);
    
    return (
        <>
            <div className="header bg-primary pb-6">
                <Container fluid>
                    <div className="header-body">
                        <Row className="align-items-center py-4">
                            <Col lg="6">
                                <h6 className="h2 text-white d-inline-block mb-0">
                                    Lead tracking
                                </h6>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
            <Container className="mt--7" fluid>
                <Row>
                    <Col className="mt-3">
                        <Card className="shadow">
                            <CardHeader>
                                <Select
                                    value={{ 
                                        value: state, 
                                        label: getStatusLabel(state)
                                    }}
                                    onChange={handleStatusChange}
                                    options={[
                                        { value: 'open', label: 'New' },
                                        { value: 'in_progress', label: getStatusLabel('in_progress') },
                                        { value: 'duplicate', label: getStatusLabel('duplicate') },
                                        { value: 'drop_off', label: getStatusLabel('drop_off') },
                                        { value: 'closed', label: getStatusLabel('closed') },
                                        { value: '', label: 'All' },
                                    ]}
                                    styles={{
                                        menu: (provided: any) => ({
                                            ...provided,
                                            zIndex: 9999
                                        }),
                                        container: (provided: any) => ({
                                            ...provided,
                                            width: '200px',
                                            marginBottom: '1rem'
                                        })
                                    }}
                                />
                                <div className='d-flex justify-content-between'>
                                    <div className='d-flex align-items-center' style={{ gap: "5px" }}>
                                        <Input
                                            type="text"
                                            placeholder="Search"
                                            className="me-2"
                                            style={{
                                                minWidth: '250px',
                                                width: '100%',
                                                border: '1px solid #f7fafc',
                                                borderRadius: '5px',
                                            }}
                                            value={query}
                                            onChange={handleQueryChange}
                                        />
                                        <ButtonGroup>
                                            <Button
                                                color="primary"
                                                outline
                                                onClick={() => handleSearchTypeChange('employee_no')}
                                                active={searchType === 'employee_no'}
                                            >
                                                Employee No
                                            </Button>
                                            <Button
                                                color="primary"
                                                outline
                                                onClick={() => handleSearchTypeChange('id_number')}
                                                active={searchType === 'id_number'}
                                            >
                                                ID Number
                                            </Button>
                                            <Button
                                                color="primary"
                                                outline
                                                onClick={() => handleSearchTypeChange('cellphone')}
                                                active={searchType === 'cellphone'}
                                            >
                                                Cellphone No
                                            </Button>
                                            <Button
                                                color="primary"
                                                outline
                                                onClick={() => handleSearchTypeChange('reference')}
                                                active={searchType === 'reference'}
                                            >
                                                Reference No
                                            </Button>
                                        </ButtonGroup>
                                    </div>

                                    <BulkUpdateModal leads={selectedLeads} getLeads={getLeads} />
                                </div>
                            </CardHeader>
                            {loading ? (
                                <Row className="my-3">
                                    <Col className="d-flex justify-content-center">
                                        <div className="spinner-border">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </Col>
                                </Row>
                            ) : (
                                <div className="table-responsive">
                                <Table
                                    className="align-items-center table-flush "
                                    style={{
                                        backgroundColor: 'white',
                                        borderBottom: '0.5pt solid #f7fafc',
                                    }}
                                >
                                    <thead>
                                        <tr>
                                            <th scope="col">
                                                <input
                                                    type="checkbox"
                                                    className=""
                                                    checked={selectedLeads.length === leads.length && leads.length > 0}
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            setSelectedLeads(leads);
                                                        } else {
                                                            setSelectedLeads([]);
                                                        }
                                                    }}
                                                />
                                            </th>
                                            <th scope="col">Product</th>
                                            <th scope="col">Employee</th>
                                            <th scope="col">Reference No.</th>
                                            <th scope="col">Date Created</th>
                                            <th scope="col">Last Updated</th>
                                            <th scope="col">Status</th>
                                            <th scope="col" />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {leads.map((lead, index) => (
                                            <tr key={`${lead.lead_id}${index}`}>
                                                <td>
                                                    <input
                                                        type="checkbox"
                                                        className=""
                                                        checked={selectedLeads.includes(lead)}
                                                        onChange={(e) => {
                                                            if (e.target.checked) {
                                                                setSelectedLeads([...selectedLeads, lead]);
                                                            } else {
                                                                setSelectedLeads(selectedLeads.filter((l) => l !== lead));
                                                            }
                                                        }}
                                                    />
                                                </td>
                                                <td>
                                                    {lead.lead_type_display} <br />
                                                    {lead.category}
                                                </td>
                                                <td>
                                                    <Link to={`/admin/employees/${lead.employee_id}`}>
                                                        {lead.employee_name}
                                                    </Link>
                                                </td>
                                                <td>{lead.lead_id}</td>
                                                <td>{dayjs(lead.created_at).format("DD MMM YYYY, hh:mm A")}</td>
                                                <td>{dayjs(lead.updated_at).format("DD MMM YYYY, hh:mm A")}</td>
                                                <td>{getStatusLabel(lead.status)}</td>
                                                <td>
                                                    <LeadDetailModal leadId={lead.lead_id} getLeads={getLeads} />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                </div>
                            )}
                        </Card>
                    </Col>
                </Row>
                <Pagination page={page} pages={pages} setPage={setPage} />
            </Container>
        </>
    )
}

export default LeadsList