export const getStatusLabel = (status: string): string => {
    switch (status) {
        case 'open':
            return 'New';
        case 'in_progress':
            return 'In Progress';
        case 'duplicate':
            return 'Duplicate';
        case 'drop_off':
            return 'Drop Off';
        case 'closed':
            return 'Resolved';
        case '':
            return 'All';
        default:
            return 'New';
    }
};

export const statusOptions = [
    { value: 'open', label: getStatusLabel('open') },
    { value: 'in_progress', label: getStatusLabel('in_progress') },
    { value: 'duplicate', label: getStatusLabel('duplicate') },
    { value: 'drop_off', label: getStatusLabel('drop_off') },
    { value: 'closed', label: getStatusLabel('closed') },
];