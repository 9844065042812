import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
// @ts-expect-error React Select types are incompatible with our component props
import Select from 'react-select';
import { Button, Modal, ModalBody } from 'reactstrap';
import { statusOptions } from './utils';
import { Lead } from './types';

interface BulkUpdateModalProps {
    leads: Lead[];
    getLeads: () => void;
}
const BulkUpdateModal = ({ leads, getLeads }: BulkUpdateModalProps) => {
    const [loading, setLoading] = React.useState(false);
    const [isOpen, setIsOpen] = React.useState(false);
    const [comment, setComment] = useState('');
    const [selectedStatus, setSelectedStatus] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);

    const handleSubmit = async () => {
        if (loading || !selectedStatus) return;
        
        setLoading(true);
        setError(null);
        
        // Get lead IDs from selected leads
        const leadIds = leads.map(lead => lead.lead_id);
        
        // Call bulk update endpoint
        axios.post('/api/leads/bulk_update_status/', {
            lead_ids: leadIds,
            status: selectedStatus,
            comment: comment.trim() || null // Send null if comment is empty
        })
        .then(() => {
            setComment('');
            setSelectedStatus(null);
            Swal.fire({
                title: 'Success',
                text: 'Leads updated successfully',
                icon: 'success',
                confirmButtonText: 'Ok',
            });
            setIsOpen(false);
            getLeads();
        })
        .catch((err) => {
            console.error("Error updating leads:", err);
            setError(err.response?.data?.detail || "Failed to update leads");
        })
        .finally(() => {
            setLoading(false);
        });
    }

    return (
        <>
            <Button
                color="primary"
                outline
                active={!loading}
                disabled={loading || leads.length === 0}
                onClick={() => setIsOpen(true)}
            >
                {loading ? 'Loading...' : 'Update'}
            </Button>
            <Modal isOpen={isOpen} size="lg">
                <ModalBody>
                    <div className='relative d-flex justify-content-between align-items-center'>
                        <h5 style={{ fontSize: '1rem' }}>Update selected Lead(s)</h5>
                        <button
                            className="btn btn-link p-0 border-0"
                            onClick={() => setIsOpen(false)}
                            aria-label="Close modal"
                        >
                            <i className="fa fa-times"></i>
                        </button>
                    </div>

                    {loading && <div className="text-center my-3">Loading...</div>}

                    {error && (
                        <div className="alert alert-danger mt-3">
                            {error}
                        </div>
                    )}

                    <br />
                    <div className="alert alert-info">
                        You are about to update {leads.length} lead{leads.length !== 1 ? 's' : ''}.
                    </div>

                    <hr />
                    <div className='d-flex' style={{ gap: '15px' }}>
                        <div style={{ maxWidth: '200px', flex: 1, display: 'flex', flexDirection: 'column', gap: '10px' }}>
                            <div>Status</div>
                            <Select
                                placeholder="Select a status"
                                value={statusOptions.find(option => option.value === selectedStatus) || null}
                                options={statusOptions}
                                onChange={(option: any) => setSelectedStatus(option ? option.value : null)}
                            />
                        </div>
                        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', gap: '10px' }}>
                            <div>
                                Note
                            </div>
                            <textarea
                                className='form-control'
                                placeholder='Add a comment'
                                rows={3}
                                value={comment}
                                onChange={(e) => setComment(e.target.value)}>
                            </textarea>
                            <Button
                                color="primary"
                                size="md"
                                className="align-self-end"
                                onClick={handleSubmit}
                                disabled={loading || !selectedStatus || comment.trim() === ''}
                            >
                                {loading ? 'Saving...' : 'Save'}
                            </Button>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </>
    );
}

export default BulkUpdateModal;