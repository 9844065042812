import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react'
// @ts-expect-error React Select types are incompatible with our component props
import Select from 'react-select';
import { Button, Modal, ModalBody, Table } from 'reactstrap';
import dayjs from 'dayjs';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import { getStatusLabel, statusOptions } from './utils';
import { Lead } from './types';

interface LeadDetailProps {
    leadId: string;
    getLeads: () => void;
}
const LeadDetailModal = ({ leadId, getLeads }: LeadDetailProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [lead, setLead] = useState<Lead>();
    const [history, setHistory] = useState<Lead[]>([]);
    const [comment, setComment] = useState('');
    const [selectedStatus, setSelectedStatus] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);

    const getTimestamp = () => `_t=${new Date().getTime()}`;

    const getLead = useCallback(() => {
        setLoading(true);
        setError(null);
        
        axios.get(`/api/leads/${leadId}?${getTimestamp()}`)
            .then((res) => res.data)
            .then((data: Lead) => {
                setLead(data);
                setSelectedStatus(data.status);
                getLeadHistory(leadId);
            })
            .catch((err) => {
                console.error("Error fetching lead:", err);
                setError("Failed to load lead details");
            })
            .finally(() => {
                setLoading(false);
            });
    }, [leadId]);

    const getLeadHistory = useCallback((id: string) => {
        setLoading(true);
        
        axios.get(`/api/leads/${id}/history/?${getTimestamp()}`)
            .then((res) => res.data)
            .then((historyData: Lead[]) => {
                setHistory([...historyData].reverse() || []);
            })
            .catch((err) => {
                // Handle 404 (not found) as empty history rather than an error
                if (err.response && err.response.status === 404) {
                    setHistory([]);
                } else {
                    console.error("Error fetching lead history:", err);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const handleSubmit = () => {
        if (loading) return;
        
        setLoading(true);
        setError(null);
        
        // Determine if we're updating status, adding a comment, or both
        const isStatusChanged = selectedStatus !== lead?.status;
        const hasComment = comment.trim() !== '';
        
        // Validation check - require comment if status isn't changing
        if (!isStatusChanged && !hasComment) {
            setError("Please add a comment or change the status");
            setLoading(false);
            return;
        }
        
        // If status is changing, use update-status endpoint
        if (isStatusChanged) {
            axios.post(`/api/leads/${leadId}/update_status/`, {
                status: selectedStatus,
                comment: comment.trim() || null // Send null if comment is empty
            })
            .then(() => {
                setComment('');
                Swal.fire({
                  title: 'Success',
                  text: 'Lead status updated successfully',
                  icon: 'success',
                  confirmButtonText: 'Ok',
                });
                setIsOpen(false);
                getLeads();
            })
            .catch((err) => {
                console.error("Error updating status:", err);
                setError(err.response?.data?.detail || "Failed to update status");
            })
            .finally(() => {
                setLoading(false);
            });
        } 
        // If only adding a comment, use add-comment endpoint
        else if (hasComment) {
            axios.post(`/api/leads/${leadId}/add_comment/`, { comment })
            .then(() => {
                setComment('');
                getLead(); // Refresh lead data
                Swal.fire({
                  title: 'Success',
                  text: 'Comment added successfully',
                  icon:'success',
                  confirmButtonText: 'Ok',
                })
            })
            .catch((err) => {
                console.error("Error adding comment:", err);
                setError(err.response?.data?.detail || "Failed to add comment");
            })
            .finally(() => {
                setLoading(false);
            });
        }
    };
    
    useEffect(() => {
        if (isOpen) {
            getLead();
        } else {
            // Reset state when modal closes
            setComment('');
            setError(null);
        }
    }, [isOpen, getLead]);

    return (
        <>
            <Button
                color="primary"
                outline
                onClick={() => {
                    setIsOpen(true);
                }}
            >
                View
            </Button>
            <Modal isOpen={isOpen} size="lg">
                <ModalBody>
                    <div className='relative d-flex justify-content-between align-items-center'>
                        <h5 style={{ fontSize: '1rem' }}>Lead Details</h5>
                        <button
                            className="btn btn-link p-0 border-0"
                            onClick={() => setIsOpen(false)}
                            aria-label="Close modal"
                        >
                            <i className="fa fa-times"></i>
                        </button>
                    </div>
                    
                    {loading && <div className="text-center my-3">Loading...</div>}
                    
                    {error && (
                        <div className="alert alert-danger mt-3">
                            {error}
                        </div>
                    )}
                    
                    {lead && (
                        <>
                            <br />
                            <div className="w-100 justify-content-between d-flex">
                                <div>
                                    <small>
                                        <strong>Product</strong><br />
                                        {lead.category}
                                    </small>
                                </div>
                                <div>
                                    <small>
                                        <strong>Employee</strong><br />
                                        <Link to={`/admin/employees/${lead.employee_id}`}>
                                            {lead.employee_name}
                                        </Link>
                                    </small>
                                </div>
                                <div>
                                    <small>
                                        <strong>Reference No.</strong><br />
                                        {lead.lead_id}
                                    </small>
                                </div>
                                <div>
                                    <small>
                                        <strong>Date Created</strong><br />
                                        {dayjs(lead.created_at).format("DD MMM YYYY, hh:mm A")}
                                    </small>
                                </div>
                                <div>
                                    <small>
                                        <strong>Status</strong><br />
                                        {getStatusLabel(lead.status)}
                                    </small>
                                </div>
                            </div>
                            <hr />
                            <h5 style={{ fontSize: '1rem' }}>Activity</h5>
                            <Table size='sm' borderless bordered={false}>
                                <thead>
                                    <tr>
                                        <th>Update type</th>
                                        <th>Date updated</th>
                                        <th>Details</th>
                                        <th>Updated by</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {history.length === 0 ? (
                                        <tr>
                                            <td colSpan={4} className="text-center">No activity history found</td>
                                        </tr>
                                    ) : (
                                        history.map((item, index) => (
                                            <tr key={`${index}-${item.lead_id}`}>
                                                <td>{item.action_display}</td>
                                                <td>{dayjs(item.created_at).format("DD MMM YYYY, hh:mm A")}</td>
                                                <td style={{
                                                    maxWidth: "200px",
                                                    overflow: "auto",
                                                    textOverflow: "ellipsis",
                                                    whiteSpace: "pre-wrap",
                                                    wordBreak: "break-word",
                                                }}>
                                                    <strong>Status:</strong> {getStatusLabel(item.status)}
                                                    {item.comment && <><br /><strong>Comment: </strong><span>{item.comment}</span></>}
                                                </td>
                                                <td>{item.user_name}</td>
                                            </tr>
                                        ))
                                    )}
                                </tbody>
                            </Table>
                            <h5 style={{ fontSize: '1rem' }}>Add an update</h5>
                            <div className='d-flex' style={{ gap: '15px' }}>
                                <div style={{ maxWidth: '200px', flex:1, display: 'flex', flexDirection: 'column', gap: '10px'  }}>
                                    <div>Status</div>
                                    <Select
                                        placeholder="Select a status"
                                        value={statusOptions.find(option => option.value === selectedStatus) || null}
                                        options={statusOptions}
                                        onChange={(option: any) => setSelectedStatus(option ? option.value : null)}
                                    />
                                </div>
                                <div style={{ flex: 1, display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                    <div>
                                        Note {selectedStatus === lead.status ? '(Required when status is not changing)' : '(Optional)'}
                                    </div>
                                    <textarea
                                        className='form-control'
                                        placeholder={selectedStatus === lead.status 
                                            ? 'Add a comment (required)' 
                                            : 'Add a comment (optional)'}
                                        rows={3}
                                        value={comment}
                                        onChange={(e) => setComment(e.target.value)}>
                                    </textarea>
                                    <Button
                                        color="primary"
                                        size="md"
                                        className="align-self-end"
                                        onClick={handleSubmit}
                                        disabled={loading || (!comment.trim() && selectedStatus === lead.status)}
                                    >
                                        {loading ? 'Saving...' : 'Save'}
                                    </Button>
                                </div>
                            </div>
                        </>
                    )}
                </ModalBody>
            </Modal>
        </>
    )
}

export default LeadDetailModal;